import PropTypes from 'prop-types';
import React from 'react';

export default function LimitSelector({
  limit,
  handleLimitChange,
  offset,
  totalCount
}) {
  function callLimitChange(e) {
    handleLimitChange(e.target.value);
  }
  function showingRowsText() {
    const firstRow = offset + 1 > totalCount ? totalCount : offset + 1;
    const lastRow = offset + limit > totalCount ? totalCount : offset + limit;

    return `Showing ${firstRow || 0} to ${lastRow || 0} of ${
      totalCount || 0
    } rows`;
  }

  return (
    <div className="cell medium-7 grid-x grid-margin-x">
      <div className="cell shrink">{showingRowsText()}</div>
      {/* onChange fires when the user selects the number
      whereas onBlur doesn't fire until clicking outside
      of the select field which is extremely poor UX */}
      <select
        name="Results per page"
        className="cell medium-4"
        // eslint-disable-next-line react/jsx-no-bind
        onChange={callLimitChange}
        defaultValue={limit}
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
      </select>
      <div className="cell shrink"> records per page</div>
    </div>
  );
}

LimitSelector.propTypes = {
  limit: PropTypes.number,
  handleLimitChange: PropTypes.func,
  offset: PropTypes.number,
  totalCount: PropTypes.number
};
