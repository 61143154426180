import PropTypes from 'prop-types';
import React from 'react';

export default function goToPageButton({
  onClick,
  clickValue,
  ariaLabel,
  name,
  disabled,
  children
}) {
  function handleClick() {
    onClick(clickValue);
  }

  return (
    <button
      type="button"
      // eslint-disable-next-line react/jsx-no-bind
      onClick={handleClick}
      aria-label={ariaLabel}
      name={name}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

goToPageButton.propTypes = {
  onClick: PropTypes.func,
  clickValue: PropTypes.number,
  ariaLabel: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node
};
