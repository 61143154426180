import PropTypes from 'prop-types';
import React from 'react';
import { Field } from '../Html5ValidationField';

const IndividualFields = ({ name }) => (
  <>
    <div className="row grid-x grid-margin-x">
      <div className="cell small-12 large-6">
        <label className="panel-label" htmlFor={`${name}.firstNames`}>
          First name
        </label>
        <Field
          id={`${name}.firstNames`}
          name={`${name}.firstNames`}
          component="input"
          type="text"
        />
      </div>
      <div className="cell small-12 large-6">
        <label className="panel-label" htmlFor={`${name}.middleNames`}>
          Middle name
        </label>
        <Field
          id={`${name}.middleNames`}
          name={`${name}.middleNames`}
          component="input"
          type="text"
        />
      </div>

      <div className="cell small-12">
        <label className="panel-label" htmlFor={`${name}.lastNames`}>
          Surname
        </label>
        <Field
          id={`${name}.lastNames`}
          name={`${name}.lastNames`}
          component="input"
          type="text"
        />
      </div>
    </div>

    <div className="row row--divider" />
    <br />

    <h2>Date Of Birth</h2>
    <div className="row grid-x grid-margin-x">
      <div className="cell small-12 medium-4">
        <label className="panel-label" htmlFor={`${name}.dateOfBirth.day`}>
          Day
        </label>
        <Field
          id={`${name}.dateOfBirth.day`}
          name={`${name}.dateOfBirth.day`}
          component="input"
          type="number"
          min={1}
          max={31}
        />
      </div>
      <div className="cell small-12 medium-4">
        <label className="panel-label" htmlFor={`${name}.dateOfBirth.month`}>
          Month
        </label>
        <Field
          id={`${name}.dateOfBirth.month`}
          name={`${name}.dateOfBirth.month`}
          component="input"
          type="number"
          min={1}
          max={12}
        />
      </div>

      <div className="cell small-12 medium-4">
        <label className="panel-label" htmlFor={`${name}.dateOfBirth.year`}>
          Year
        </label>
        <Field
          id={`${name}.dateOfBirth.year`}
          name={`${name}.dateOfBirth.year`}
          component="input"
          type="number"
          min={1920}
          max={2040}
        />
      </div>
    </div>

    <div className="row row--divider" />

    <label className="panel-label" htmlFor={`${name}.postalAddress`}>
      Address
    </label>
    <Field
      id={`${name}.postalAddress`}
      name={`${name}.postalAddress`}
      component="input"
      type="text"
    />

    <div className="grid-x grid-margin-x row">
      <div className="cell small-12 medium-6">
        <label className="panel-label" htmlFor={`${name}.phoneNumber`}>
          Phone number
        </label>
        <Field
          id={`${name}.phoneNumber`}
          name={`${name}.phoneNumber`}
          component="input"
          type="tel"
        />
      </div>
      <div className="cell small-12 medium-6">
        <label className="panel-label" htmlFor={`${name}.email`}>
          Email
        </label>
        <Field
          id={`${name}.email`}
          name={`${name}.email`}
          component="input"
          type="email"
        />
      </div>
    </div>
  </>
);

IndividualFields.propTypes = {
  name: PropTypes.string.isRequired
};

export default IndividualFields;
