import PropTypes from 'prop-types';
import React from 'react';
import { Field } from '../Html5ValidationField';

const FormGroupContext = React.createContext();

const FormGroup = ({ prefix, children }) => (
  <FormGroupContext.Provider value={prefix}>
    {children}
  </FormGroupContext.Provider>
);

const FormGroupField = ({ name, ...props }) => {
  return (
    <FormGroupContext.Consumer>
      {prefix => (
        <Field name={prefix ? `${prefix}.${name}` : name} {...props} />
      )}
    </FormGroupContext.Consumer>
  );
};

FormGroup.propTypes = {
  prefix: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node)
};

FormGroupField.propTypes = {
  name: PropTypes.string.isRequired
};

export { FormGroup, FormGroupField, FormGroupContext };
