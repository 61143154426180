import { compact, uniqBy } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Field } from '../Html5ValidationField';
import IndividualFields from './individual-fields';
import VesselFields from './vessel-fields';
import bulletList from '../../images/bullet-list.svg';
import calendarIcon from '../../images/calendar.svg';
import clockIcon from '../../images/clock.svg';
import plus from '../../images/plus-circle.svg';
import AutosaveSpy from '../forms/autosave/AutosaveSpy';
import AutosaveWarning from '../forms/autosave/AutosaveWarning';
import ClassificationSelect from '../forms/classification-select';
import IncidentDecorator from '../forms/decorators/IncidentDecorator';
import LocationSelect from '../forms/location-select';
import TaxonomyChildSelect from '../forms/taxonomy-child-select';
import TaxonomySelect from '../forms/taxonomy-select';

const prepareIncidentFields = values => {
  values.involvedIndividuals = (values.involvedIndividuals || []).filter(
    val => val
  );

  values.involvedVessels = (values.involvedVessels || []).filter(val => val);

  return values;
};

const IncidentFields = props => {
  const {
    operationalBounds,
    incident,
    defaultPosition,
    form,
    incidentCategories,
    harbourUserGroups,
    harbourmasterVessels,
    users,
    operationalAreas,
    warning,
    setWarning,
    storageKey
  } = props;
  const {
    mutators: { push }
  } = form;

  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [userOptions, setUserOptions] = useState([]);

  useEffect(
    () =>
      (async () => {
        const user = incident && (await incident.populate('user'));
        setUserOptions(uniqBy(compact(users.concat([user])), 'id'));
      })() && undefined,
    [incident, users]
  );

  return (
    <>
      <AutosaveSpy
        storageKey={storageKey}
        DecoratorComponent={IncidentDecorator}
      />

      <AutosaveWarning
        storageKey={storageKey}
        warning={warning}
        setWarning={setWarning}
        form={form}
      />
      <fieldset className="form-section panel panel--large summary">
        <legend>
          <h2>Incident details</h2>
        </legend>

        <div className="row grid-margin-x">
          <div className="block--6">
            <label className="panel-label" htmlFor="date">
              Date of Incident
            </label>
            <div className="input-group">
              <Field
                required
                component="input"
                id="date"
                className="input-group-field"
                name="date"
                type="date"
              />
              <div className="input-group-label">
                <img
                  alt="Calendar"
                  style={{ width: '0.9rem' }}
                  src={calendarIcon}
                />
              </div>
            </div>
          </div>

          <div className="block--6">
            <label className="panel-label" htmlFor="time">
              Time of Incident
            </label>
            <div className="input-group">
              <Field
                required
                component="input"
                id="time"
                className="input-group-field"
                name="time"
                type="time"
              />
              <div className="input-group-label">
                <img alt="Clock" style={{ width: '0.9rem' }} src={clockIcon} />
              </div>
            </div>
          </div>
        </div>

        <ClassificationSelect
          id="user"
          name="user"
          label="Recorded by"
          labelClass="panel-label"
          collection={userOptions}
        />

        <ClassificationSelect
          id="harbourmasterVessel"
          name="harbourmasterVessel"
          label="Patrol vessel"
          labelClass="panel-label"
          includeBlank
          collection={harbourmasterVessels}
        />
      </fieldset>

      <fieldset className="form-section panel panel--large summary">
        <TaxonomySelect
          id="parentHarboursUserGroup"
          name="parentHarboursUserGroup"
          label="Harbour user group"
          includeBlank
          labelClass="panel-label"
          collection={harbourUserGroups}
          required
        />

        <TaxonomyChildSelect
          parents={harbourUserGroups}
          parentAttribute="parentHarboursUserGroup"
          childAttribute="harboursUserGroup"
          label="Harbour user sub-group"
          labelClass="panel-label"
          required
        />
      </fieldset>

      <fieldset className="form-section panel panel--large summary">
        <legend>
          <h2>Location</h2>
        </legend>

        <LocationSelect
          form={form}
          bounds={operationalBounds}
          defaultPosition={defaultPosition}
        />

        <TaxonomySelect
          id="parentOperationalArea"
          name="parentOperationalArea"
          includeBlank
          required
          labelClass="panel-label"
          label="Operational area"
          collection={operationalAreas}
        />

        <TaxonomyChildSelect
          parents={operationalAreas}
          parentAttribute="parentOperationalArea"
          childAttribute="operationalArea"
          label="Operational sub-area"
          labelClass="panel-label"
        />
      </fieldset>

      <fieldset className="form-section panel panel--large summary">
        <h2>Description</h2>
        <label className="panel-label" htmlFor="description">
          Incident Description
        </label>
        <Field
          component="textarea"
          id="description"
          name="description"
          cols="30"
          rows="10"
          required
        />

        <div className="wrappable-input-group">
          <div className="input-group panel-label panel panel--bordered panel--compact wrappable-input">
            <Field name="breachNoticeServed" type="checkbox">
              {fieldProps => {
                return (
                  <>
                    <label className="checkbox-label margin-bottom-0 flex-child-grow">
                      <span>Breach notice served</span>
                      <input {...fieldProps.input} />
                      <span className="checkmark" />
                    </label>
                  </>
                );
              }}
            </Field>
          </div>
          <div className="wrappable-input" />
          <div className="wrappable-input" />
        </div>

        <p className="panel-label">Attachment Types</p>

        <div className="row wrappable-input-group">
          <div className="input-group panel panel--bordered panel--compact wrappable-input">
            <Field name="bodyCamAttachments" type="checkbox">
              {fieldProps => {
                return (
                  <>
                    <label className="checkbox-label margin-bottom-0 flex-child-grow">
                      <span>Body camera</span>
                      <input {...fieldProps.input} />
                      <span className="checkmark" />
                    </label>
                  </>
                );
              }}
            </Field>
          </div>
          <div className="input-group panel panel--bordered panel--compact wrappable-input">
            <Field name="logBookAttachments" type="checkbox">
              {fieldProps => {
                return (
                  <>
                    <label className="checkbox-label margin-bottom-0 flex-child-grow">
                      <span>Logbooks and paperwork</span>
                      <input {...fieldProps.input} />
                      <span className="checkmark" />
                    </label>
                  </>
                );
              }}
            </Field>
          </div>

          <div className="input-group panel panel--bordered panel--compact wrappable-input">
            <Field name="deviceAttachments" type="checkbox">
              {fieldProps => {
                return (
                  <>
                    <label className="checkbox-label margin-bottom-0 flex-child-grow">
                      <span>Device</span>
                      <input {...fieldProps.input} />
                      <span className="checkmark" />
                    </label>
                  </>
                );
              }}
            </Field>
          </div>
        </div>
      </fieldset>

      <FieldArray name="involvedIndividuals">
        {({ fields }) =>
          fields.map((name, index) => (
            <fieldset
              key={name}
              className="individual-fields form-section panel panel--large summary"
            >
              <legend className="grid-x">
                <div className="cell auto">
                  <h2>Individual Involved</h2>
                </div>
                <div className="cell shrink">
                  <button
                    /* eslint-disable-next-line react/jsx-no-bind */
                    onClick={() => fields.remove(index)}
                    type="button"
                    className="cell shrink"
                    style={{ cursor: 'pointer' }}
                  >
                    Remove
                  </button>
                </div>
              </legend>

              <IndividualFields name={name} />
            </fieldset>
          ))
        }
      </FieldArray>

      <FieldArray name="involvedVessels">
        {({ fields }) =>
          fields.map((name, index) => (
            <fieldset
              key={name}
              className="vessel-fields form-section panel panel--large summary"
            >
              <legend className="grid-x">
                <div className="cell auto">
                  <h2>Vessel Involved</h2>
                </div>
                <div className="cell shrink">
                  <button
                    /* eslint-disable-next-line react/jsx-no-bind */
                    onClick={() => fields.remove(index)}
                    type="button"
                    className="cell shrink"
                    style={{ cursor: 'pointer' }}
                  >
                    Remove
                  </button>
                </div>
              </legend>

              <VesselFields name={name} />
            </fieldset>
          ))
        }
      </FieldArray>

      {showAdditionalFields ? (
        <fieldset className="form-section panel panel--large summary">
          <legend>
            <h2>Categorise Incident</h2>
          </legend>

          <TaxonomySelect
            id="category"
            name="category"
            label="Incident category"
            includeBlank
            labelClass="panel-label"
            collection={incidentCategories}
          />

          <TaxonomyChildSelect
            parents={incidentCategories}
            parentAttribute="category"
            childAttribute="subcategory"
            labelClass="panel-label"
            label="Incident sub-category"
          />
        </fieldset>
      ) : null}

      <div className="panel panel--large summary">
        <div className="wrappable-button-group row">
          {/* eslint-disable react/jsx-no-bind */}
          <button
            type="button"
            onClick={() => push('involvedIndividuals', null)}
            className="button padded tertiary align-center wrappable-button"
          >
            <img src={plus} className="icon" alt="" />
            Add individual
          </button>
          <button
            type="button"
            onClick={() => push('involvedVessels', null)}
            className="button padded tertiary align-center wrappable-button"
          >
            <img src={plus} className="icon" alt="" />
            Add vessel
          </button>
          <button
            type="button"
            onClick={() => setShowAdditionalFields(!showAdditionalFields)}
            className="button padded tertiary align-center wrappable-button"
          >
            <img src={bulletList} className="icon" alt="" />
            Categorise
          </button>
          {/* eslint-enable react/jsx-no-bind */}
        </div>
      </div>
    </>
  );
};

IncidentFields.propTypes = {
  form: PropTypes.object,
  storageKey: PropTypes.string,
  setWarning: PropTypes.func,
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  prepare: PropTypes.func,
  incident: PropTypes.object,
  defaultPosition: PropTypes.arrayOf(PropTypes.number),
  operationalBounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  incidentCategories: PropTypes.arrayOf(PropTypes.object),
  harbourUserGroups: PropTypes.arrayOf(PropTypes.object),
  harbourmasterVessels: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  operationalAreas: PropTypes.arrayOf(PropTypes.object)
};

IncidentFields.defaultProps = {
  prepare: prepareIncidentFields
};

export default IncidentFields;
