import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useRxCollection } from 'rxdb-hooks';
import { extraClassifications } from './data/actions-extra-classifications';
import { filters } from './data/actions-filters';
import { headers } from './data/actions-headers';
import getActionProperties from './data/get-action-properties';
import useInitialFilterValues from '../common/hooks/useInitialFilterValues';
import useURLParams from '../common/hooks/useURLParams';
import { roundDown } from '../common/roundDown';
import DataTable from '../dataTable/data-table';
import FilterFormContainer from '../dataTable/filter-form-container';
import LimitSelector from '../dataTable/limit-selector';
import Pagination from '../dataTable/pagination';
import SearchForm from '../dataTable/search-form';
import { COLLECTION as ACTIONS_QUERY } from '../graphql/queries/actions';
const queryString = require('query-string');

export default function ActionsDataTable({ filterOptions }) {
  const initialQuery = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: 'index'
  });

  const [limit, setLimit] = useState(initialQuery.limit || 50);
  const [offset, setOffset] = useState(initialQuery.offset || 0);
  const [sortBy, setSortBy] = useState({
    order: initialQuery.order || 'created_at',
    dir: initialQuery.dir || 'DESC'
  });
  const [appliedFilters, setAppliedFilters] = useState(() => {
    return (
      (initialQuery.appliedFilters &&
        initialQuery.appliedFilters.map(filter => JSON.parse(filter))) ||
      []
    );
  });
  const [search, setSearch] = useState(initialQuery.search || '');

  const { error, data, refetch, variables } = useQuery(ACTIONS_QUERY, {
    variables: {
      offset: offset,
      limit: limit,
      sort: sortBy,
      filters: appliedFilters,
      search: search
    },
    fetchPolicy: 'cache-and-network'
  });

  if (error) {
    console.error(error);
  }

  useURLParams({
    search,
    appliedFilters,
    limit,
    offset,
    sortBy
  });

  const actionsCollection = useRxCollection('actions');
  useEffect(() => {
    if (!actionsCollection) {
      return;
    }

    const collectionSubscription = actionsCollection.$.subscribe(() => {
      console.log('Actions collection changed, refetching table data');
      refetch();
    });

    return () => collectionSubscription.unsubscribe();
  }, [actionsCollection, refetch]);

  const handleLimitChange = useCallback(
    newLimit => {
      // round down to the nearest multiple of the new limit
      const newOffset = roundDown(offset, newLimit);
      setOffset(newOffset);

      setLimit(Number(newLimit));
      refetch({ limit, offset });
      window.scrollTo({ top: 0 });
    },
    [limit, offset, refetch]
  );

  const handleFilterChange = useCallback(
    e => {
      let newFilters = Object.keys(e).map(key => {
        const identifier = `${key}`;

        return {
          identifier,
          value: e[key]
        };
      });

      setOffset(0);
      setAppliedFilters([]);
      setAppliedFilters(newFilters);
      refetch(appliedFilters);
    },
    [appliedFilters, refetch]
  );

  const handleSortByChange = useCallback(
    newSortByName => {
      const {
        sort: { order, dir }
      } = variables;
      const currentSortByName = order;

      let newSort = {
        order: 'created_at',
        dir: 'DESC'
      };

      if (newSortByName && newSortByName !== currentSortByName) {
        newSort = {
          order: newSortByName,
          dir: 'ASC'
        };
      }

      if (newSortByName === currentSortByName && dir === 'ASC') {
        newSort = {
          order: currentSortByName,
          dir: 'DESC'
        };
      }

      if (newSortByName === 'created_at' && dir === 'DESC') {
        newSort = {
          order: 'created_at',
          dir: 'ASC'
        };
      }

      setSortBy(newSort);
      refetch({
        sortBy
      });
    },
    [refetch, sortBy, variables]
  );

  function clearFilters(form) {
    setAppliedFilters([]);
    setSearch('');
    form.reset();
    refetch(appliedFilters);
  }

  const handleSearchChange = useCallback(
    newSearch => {
      setOffset(0);
      setSearch(newSearch['table-search']);
      refetch(search);
    },
    [refetch, search]
  );

  function goToPage(newPageNumber) {
    newPageNumber > 0 && newPageNumber < pageCount / limit;
    setOffset((Number(newPageNumber) - 1) * limit);
    refetch({
      limit,
      offset
    });
  }

  const actionsData = data && data.actionCollection;
  const totalCount = actionsData && data.actionCollection.pageInfo.count;

  const pageCount =
    actionsData && Math.ceil(actionsData.pageInfo.count / variables.limit);

  const tableData = useMemo(
    () => actionsData && actionsData.collection.map(getActionProperties),
    [actionsData]
  );

  const initialValues = useInitialFilterValues(appliedFilters);

  return (
    <>
      <div className="grid-x grid-margin-x align-justify">
        <Form
          onSubmit={handleSearchChange}
          placeholder={'Search Actions'}
          render={SearchForm}
          initialValues={{
            'table-search': search
          }}
        />
        <Form
          resource={'actions'}
          onSubmit={handleFilterChange}
          render={FilterFormContainer}
          initialValues={initialValues}
          appliedFilters={appliedFilters}
          // eslint-disable-next-line react/jsx-no-bind
          clearFilters={clearFilters}
          extraClassifications={{
            ...extraClassifications,
            navigationAids: filterOptions.navigationAids
          }}
          filters={filters}
        />
      </div>
      <DataTable
        resource={'actions'}
        tableData={tableData}
        headers={headers}
        handleSortByChange={handleSortByChange}
      />
      <div className="grid-x align-justify">
        <LimitSelector
          offset={offset}
          limit={limit}
          handleLimitChange={handleLimitChange}
          totalCount={totalCount}
        />
        <Pagination
          limit={limit}
          offset={offset}
          // eslint-disable-next-line react/jsx-no-bind
          goToPage={goToPage}
          pageCount={pageCount}
        />
      </div>
    </>
  );
}

ActionsDataTable.propTypes = {
  filterOptions: PropTypes.object
};
