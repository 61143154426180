import { FORM_ERROR } from 'final-form';
import pick from 'lodash-es/pick';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import { defaultOption as defaultCheckIntervalOption } from './data/check-interval-options';
import NavigationAidFields from './navigation-aid-fields';
import ClassificationsContext from '../common/ClassificationsContext';
import ApplicationConfigContext from '../common/application-config-context';
import { transformStringToArray } from '../common/transform-string-to-array';
import { UUID } from '../db/helpers';
import { withRxDB } from '../db/hoc';
import { ATON_CATEGORY_NAME } from '../db/schemas/navigationAid';
import { POINT_DEFAULT_SRID } from '../db/schemas/point';

function Create(props) {
  const { db, history, assetCategories } = props;
  const collection = db.collections.navigationaids;
  const initialValues = {
    checkIntervalMonths: defaultCheckIntervalOption.months,
    category: assetCategories.find(cat => cat.name === ATON_CATEGORY_NAME)?.id
  };

  function onSubmit(values) {
    /* eslint-disable camelcase */
    let submissionErrors = {};
    const { latitude, longitude, atonCategory, battery_id } = values;
    delete values.latitude;
    delete values.longitude;

    if (atonCategory) {
      values.atonCategory = Number(atonCategory);
    }

    if (longitude && latitude) {
      values.location = {
        lng: parseFloat(longitude),
        lat: parseFloat(latitude),
        srid: POINT_DEFAULT_SRID
      };
    }

    if (battery_id) {
      values.battery_id = transformStringToArray(battery_id, /\d+/g);
    }

    values.metadata = pick(values, [
      'battery_type',
      'battery_id',
      'light_type',
      'light_id'
    ]);

    delete values.battery_id;
    delete values.battery_type;
    delete values.light_id;
    delete values.light_type;

    /* eslint-enable camelcase */

    if (!values.operationalArea && values.parentOperationalArea) {
      values.operationalArea = values.parentOperationalArea;
    }

    values.id = UUID.generate();

    return collection
      .insert(values)
      .then(doc =>
        history.push(
          `/navigation_aids/${doc.id}?status_message=navigation_aids.create.success`
        )
      )
      .catch(err => {
        submissionErrors[[FORM_ERROR]] = err.message || err;

        return submissionErrors;
      });
  }

  return (
    <div className="page page--white-background">
      <div className="row row--divider">
        <h1>New AtoN</h1>
      </div>

      <div className="grid-x grid-padding-x row row--spaced margin-top-2">
        <div className="cell auto">
          {/* eslint-disable-next-line react/jsx-no-bind */}
          <Form onSubmit={onSubmit} initialValues={initialValues}>
            {({
              handleSubmit,
              submitting,
              form,
              submitError,
              values: params
            }) => (
              <form onSubmit={handleSubmit} className="grid-x">
                <div className="cell small-auto large-8 large-offset-2">
                  {submitError && (
                    <div className="alert alert-danger">{submitError}</div>
                  )}

                  <ApplicationConfigContext.Consumer>
                    {({ defaultPosition, operationalBounds }) => (
                      <ClassificationsContext.Consumer>
                        {classifications => (
                          <NavigationAidFields
                            {...classifications}
                            defaultPosition={defaultPosition}
                            operationalBounds={operationalBounds}
                            form={form}
                            params={params}
                          />
                        )}
                      </ClassificationsContext.Consumer>
                    )}
                  </ApplicationConfigContext.Consumer>

                  <div className="row grid-x">
                    <div className="cell auto" />
                    <div className="cell padding-left-2 padding-right-2">
                      <button
                        className="button primary expanded padded"
                        type="submit"
                        disabled={submitting}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}

Create.propTypes = {
  operationalAreas: PropTypes.arrayOf(PropTypes.object),
  assetCategories: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired
};

export default withRouter(withRxDB(Create));
