import { useEffect } from 'react';
import { setActionUrgency } from './decorator-helpers';
import { UUID } from '../../db/helpers';

export default function IncidentActionDecorator(props) {
  /* eslint-disable react/prop-types */
  const {
    values,
    form: { batch, change }
  } = props;
  const {
    parentOperationalArea,
    operationalArea,
    asset,
    requestedBy,
    user,
    latitude,
    longitude,
    incidentAction
  } = values;
  /* eslint-enable */

  const { isPriority, date } = incidentAction || {};
  const currentUser = requestedBy || user;
  /**
   * This first useEffect is used to initialize some values that
   * should always be cohersed/set
   * Note that this assumes follow up actions are always being created
   * as opposed to edited
   * If editing needs to be supported, these values
   * should defer to incidentAction if they're set
   */

  useEffect(() => {
    batch(() => {
      change('incidentAction.id', UUID.generate());
      change('incidentAction.status', 'open');
      change('incidentAction.datetime', date);
      change('incidentAction.requestedBy', currentUser);
      change('incidentAction.parentOperationalArea', parentOperationalArea);
      change('incidentAction.operationalArea', operationalArea);
      change('incidentAction.asset', asset);
      change('incidentAction.latitude', latitude);
      change('incidentAction.longitude', longitude);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * The next set of useEffects are used to coherse values that need to be updated
   * whenever their associated fields are updated
   */

  useEffect(() => {
    change('incidentAction.datetime', date);
  }, [date, change]);

  useEffect(() => {
    setActionUrgency({
      change,
      isPriority,
      fieldName: 'incidentAction.urgency'
    });
  }, [isPriority, change]);

  /**
   * These useEffect below here are used to set values that should be reset to
   * the value of the resource on the first page if those values are changed
   */

  useEffect(() => {
    change('incidentAction.parentOperationalArea', parentOperationalArea);
  }, [parentOperationalArea, change]);

  useEffect(() => {
    change('incidentAction.operationalArea', operationalArea);
  }, [operationalArea, change]);

  useEffect(() => {
    change('incidentAction.asset', asset);
  }, [asset, change]);

  useEffect(() => {
    change('incidentAction.latitude', latitude);
  }, [latitude, change]);

  useEffect(() => {
    change('incidentAction.longitude', longitude);
  }, [longitude, change]);

  return null;
}
