import PropTypes from 'prop-types';
import React from 'react';
import { removeAutosaveItem } from './autosave-helpers';
import criticalAction from '../../../images/critical-action.svg';

const AutosaveWarning = ({ form, storageKey, warning, setWarning }) => {
  if (!warning) {
    return null;
  }

  function handleClearForm() {
    let confirmation = confirm(
      'Are you sure you want to delete your previously entered data?'
    );
    if (confirmation) {
      removeAutosaveItem(storageKey);
      form.restart();
      setWarning(false);
    }
  }

  function handleKeyPress(e) {
    if (e.keyCode === 13) {
      handleClearForm();
    }
  }

  const warnings = {
    updatedSince: {
      title:
        'We are showing you data you entered previously but didn’t save. Since then, this record has been updated.',
      subTitle: (
        <b>
          If you proceed you will overwrite the changes that have been
          made.&nbsp;
        </b>
      ),
      action:
        'Clear your previously entered data and proceed with editing the latest data instead.'
    },
    saved: {
      title: (
        <b>We are showing you data you entered previously but didn’t save.</b>
      ),
      subTitle: 'Continue completing this form or ',
      action: 'clear your previously entered data.'
    }
  };

  const message = warnings[warning];

  return message ? (
    <div className="alert__hollow grid-x margin-bottom-2">
      <div className="cell small-1 align-self-middle alert__icon">
        <img src={criticalAction} className="icon" alt="Notice" />
      </div>
      <div className="cell auto align-self-middle alerts__message">
        <span>
          {message.title}
          <br />
          <span>{message.subTitle}</span>
          <b
            className="link-button"
            role="button"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleClearForm}
            // eslint-disable-next-line react/jsx-no-bind
            onKeyPress={handleKeyPress}
            tabIndex={0}
          >
            {message.action}
          </b>
        </span>
      </div>
    </div>
  ) : null;
};

AutosaveWarning.propTypes = {
  storageKey: PropTypes.string,
  warning: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  setWarning: PropTypes.func,
  form: PropTypes.object
};

export default AutosaveWarning;
