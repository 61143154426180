import { useCallback, useEffect } from 'react';

export default function LogEntryDecorator(props) {
  /* eslint-disable react/prop-types */
  const {
    values: { date, time, logTime },
    form: { change }
  } = props;
  /* eslint-enable */

  const setLogTime = useCallback(() => {
    const newTime =
      date && time ? new Date(`${date} ${time}`).getTime() : undefined;

    newTime && change('logTime', newTime);
  }, [change, date, time]);

  useEffect(() => {
    if (date && time && !logTime) {
      setLogTime();
    }
  });

  useEffect(() => {
    setLogTime();
  }, [setLogTime]);

  return null;
}
