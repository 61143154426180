import PropTypes from 'prop-types';
import React from 'react';
import { Field } from '../Html5ValidationField';

export default function SearchForm({ handleSubmit, placeholder = 'Search' }) {
  return (
    <form className=" grid-x cell shrink" onSubmit={handleSubmit}>
      <Field
        component="input"
        className="cell shrink"
        onBlur={handleSubmit}
        placeholder={placeholder}
        type="text"
        name={'table-search'}
        id={'table-search'}
      />
    </form>
  );
}

SearchForm.propTypes = {
  handleSubmit: PropTypes.func,
  placeholder: PropTypes.string
};
