import { FORM_ERROR } from 'final-form';
import pick from 'lodash-es/pick';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import {
  formatOption as formatCheckIntervalOption,
  parseOption as parseCheckIntervalOption
} from './data/check-interval-options';
import NavigationAidFields from './navigation-aid-fields';
import ClassificationsContext from '../common/ClassificationsContext';
import ApplicationConfigContext from '../common/application-config-context';
import useRxDocument from '../common/hooks/useRxDocument';
import { includeRemovedValues } from '../common/include-removed-values';
import { transformStringToArray } from '../common/transform-string-to-array';
import { POINT_DEFAULT_SRID } from '../db/schemas/point';

function buildInitialValues(navigationAid) {
  const {
    id,
    name,
    identificationNumber,
    locationDescription,
    location,
    operationalArea,
    checkIntervalMonths,
    parentOperationalArea,
    metadata = {},
    category,
    subcategory,
    atonCategory
  } = navigationAid;

  /* eslint-disable camelcase */
  const { battery_type, battery_id, light_type, light_id } = metadata;
  const { lat, lng } = location || {};

  return {
    longitude: lng || '',
    latitude: lat || '',
    parentOperationalArea,
    operationalArea,
    name,
    identificationNumber,
    locationDescription,
    id,
    atonCategory,
    category,
    subcategory,
    battery_type,
    battery_id,
    light_type,
    light_id,
    checkIntervalMonths: formatCheckIntervalOption(checkIntervalMonths)
  };
  /* eslint-enable camelcase */
}
function Edit(props) {
  const { navigationAid, history } = props;

  async function onSubmit(values) {
    let submissionErrors = {};
    const allValues = includeRemovedValues(
      buildInitialValues(navigationAid),
      values
    );

    /* eslint-disable camelcase */
    const { latitude, longitude, atonCategory, battery_id } = allValues;
    delete allValues.latitude;
    delete allValues.longitude;

    if (atonCategory) {
      allValues.atonCategory = Number(atonCategory);
    }

    allValues.checkIntervalMonths = parseCheckIntervalOption(
      allValues.checkIntervalMonths
    );

    if (longitude && latitude) {
      allValues.location = {
        lng: parseFloat(longitude),
        lat: parseFloat(latitude),
        srid: POINT_DEFAULT_SRID
      };
    }

    if (battery_id) {
      allValues.battery_id = transformStringToArray(battery_id, /\d+/g);
    }

    allValues.metadata = pick(allValues, [
      'battery_type',
      'battery_id',
      'light_type',
      'light_id'
    ]);

    delete allValues.battery_id;
    delete allValues.battery_type;
    delete allValues.light_id;
    delete allValues.light_type;

    /* eslint-enable camelcase */

    if (!allValues.operationalArea && allValues.parentOperationalArea) {
      allValues.operationalArea = allValues.parentOperationalArea;
    }

    return navigationAid
      .incrementalPatch(allValues)
      .then(() =>
        history.push(
          `/navigation_aids/${navigationAid.id}?status_message=navigation_aids.update.success`
        )
      )
      .catch(err => {
        submissionErrors[[FORM_ERROR]] = err.message || err;

        return submissionErrors;
      });
  }

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <Form initialValues={buildInitialValues(navigationAid)} onSubmit={onSubmit}>
      {({ handleSubmit, submitting, form, submitError, values: params }) => (
        <form onSubmit={handleSubmit} className="grid-x">
          <div className="cell small-auto large-8 large-offset-2">
            {submitError && (
              <div className="alert alert-danger">{submitError}</div>
            )}

            <ApplicationConfigContext.Consumer>
              {({ defaultPosition, operationalBounds }) => (
                <ClassificationsContext.Consumer>
                  {classifications => (
                    <NavigationAidFields
                      {...classifications}
                      defaultPosition={defaultPosition}
                      operationalBounds={operationalBounds}
                      form={form}
                      params={params}
                    />
                  )}
                </ClassificationsContext.Consumer>
              )}
            </ApplicationConfigContext.Consumer>

            <div className="row grid-x">
              <div className="cell auto" />
              <div className="cell padding-left-2 padding-right-2">
                <button
                  className="button primary expanded padded"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
}

Edit.propTypes = {
  history: PropTypes.object.isRequired,
  navigationAid: PropTypes.object.isRequired
};

const WrappedEdit = props => {
  const { id } = props.match.params;
  const history = useHistory();
  const { result: navigationAid, isFetching } = useRxDocument(
    'navigationaids',
    id
  );

  if (isFetching) {
    return 'Loading...';
  }

  if (!isFetching && !navigationAid) {
    return 'Not found...';
  }

  return <Edit history={history} navigationAid={navigationAid} />;
};

WrappedEdit.propTypes = {
  match: PropTypes.object.isRequired
};

export default WrappedEdit;
