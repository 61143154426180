import PropTypes from 'prop-types';
import React, { useRef, useCallback } from 'react';
import Select from 'react-select';

const styles = {
  control: (base, state) => ({
    ...base,
    'boxShadow': state.isFocused ? '0 0 0 1px #0079c1' : undefined,
    'border': state.isFocused ? 'none' : `1px solid ${base.borderColor}`,
    '&:hover': {
      border: state.isFocused ? 'none' : `1px solid ${base.borderColor}`
    }
  }),
  menu: base => ({
    ...base,
    marginTop: 0,
    boxShadow: '0 0 0 1px #0079c1',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  }),
  singleValue: base => ({
    ...base,
    padding: 5,
    borderRadius: 5
  }),
  valueContainer: base => ({
    ...base,
    padding: '0 8px'
  }),
  placeholder: base => ({
    ...base,
    paddingLeft: '4px',
    color: '#757575'
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#333'
  }),
  option: base => ({
    ...base,
    minHeight: 35
  }),
  input: base => ({
    ...base,
    'marginTop': '1rem',
    'padding': 0,
    '[type="text"]:focus': {
      boxShadow: 'none'
    }
  })
};

export default function CustomSelect({
  input,
  options,
  isSearchable = true,
  isClearable,
  ariaLabel,
  ...rest
}) {
  function noOptionsMessage() {
    return 'Loading...';
  }

  function handleChange(item) {
    return item ? input.onChange(item.value) : input.onChange('');
  }

  function IndicatorSeparator() {
    return null;
  }

  function noop() {
    // no operation (do nothing real quick)
  }

  const val = options.find(opt => opt.value === input.value) || '';

  const selectRef = useRef(null);

  function focusSelect() {
    selectRef && selectRef.current.focus();
  }

  // Workaround a react-select issue where the element doesn't scroll
  // to the selected element when the input is gains focussed.
  const onMenuOpen = useCallback(() => {
    setTimeout(
      () =>
        selectRef.current.select &&
        selectRef.current.select.menuListRef &&
        selectRef.current.select.menuListRef.querySelector(
          '.react-select__option--is-selected'
        ) &&
        selectRef.current.select.menuListRef
          .querySelector('.react-select__option--is-selected')
          .scrollIntoView({
            block: 'nearest',
            inline: 'start'
          })
    );
  }, [selectRef]);

  // React select does not support native required attr as currently
  // https://github.com/JedWatson/react-select/issues/4327
  // Based on the above it seems likely to be fixed in an upcoming release
  // However for now this component uses the workaround of an adjacent input

  return (
    <>
      <Select
        {...input}
        {...rest}
        aria-label={ariaLabel}
        ref={selectRef}
        options={options}
        value={val}
        onMenuOpen={onMenuOpen}
        classNamePrefix="react-select"
        components={{ IndicatorSeparator }}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={handleChange}
        // eslint-disable-next-line react/jsx-no-bind
        noOptionsMessage={noOptionsMessage}
        isClearable={isClearable}
        isSearchable={isSearchable}
        styles={styles}
      />
      {!input.disabled && (
        <input
          aria-hidden="true"
          tabIndex={-1}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={noop}
          autoComplete="off"
          style={{
            opacity: 0,
            height: 0
          }}
          value={val}
          // eslint-disable-next-line react/jsx-no-bind
          onFocus={focusSelect}
          required={rest.required}
        />
      )}
    </>
  );
}

CustomSelect.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  ariaLabel: PropTypes.string
};
