import { FORM_ERROR } from 'final-form';
import pick from 'lodash-es/pick';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import AssetFields from './asset-fields';
import ClassificationsContext from '../common/ClassificationsContext';
import ApplicationConfigContext from '../common/application-config-context';
import useRxDocument from '../common/hooks/useRxDocument';
import { includeRemovedValues } from '../common/include-removed-values';
import { ATON_CATEGORY_NAME } from '../db/schemas/navigationAid';

function buildInitialValues(asset) {
  const {
    id,
    name,
    category,
    subcategory,
    locationDescription,
    description,
    metadata,
    checkIntervalMonths,
    operationalArea,
    parentOperationalArea
  } = asset;

  const { serialNumber, purchaseDate, supplier } = metadata;

  return {
    id,
    name,
    category,
    subcategory,
    locationDescription,
    description,
    serialNumber,
    purchaseDate,
    supplier,
    checkIntervalMonths,
    operationalArea,
    parentOperationalArea
  };
}

function Edit(props) {
  const { asset, history } = props;

  async function onSubmit(values) {
    let submissionErrors = {};
    const allValues = includeRemovedValues(buildInitialValues(asset), values);

    if (!allValues.subcategory && allValues.category) {
      allValues.subcategory = allValues.category;
    }

    allValues.metadata = pick(allValues, [
      'serialNumber',
      'purchaseDate',
      'supplier'
    ]);

    delete allValues.serialNumber;
    delete allValues.purchaseDate;
    delete allValues.supplier;

    if (!allValues.operationalArea && allValues.parentOperationalArea) {
      allValues.operationalArea = allValues.parentOperationalArea;
    }

    return asset
      .incrementalPatch(allValues)
      .then(() =>
        history.push(
          `/manage-assets/${asset.id}?status_message=assets.update.success`
        )
      )
      .catch(err => {
        submissionErrors[[FORM_ERROR]] = err.message || err;

        return submissionErrors;
      });
  }

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <Form initialValues={buildInitialValues(asset)} onSubmit={onSubmit}>
      {({ handleSubmit, submitting, form, submitError, values: params }) => (
        <form onSubmit={handleSubmit} className="grid-x">
          <div className="cell small-auto large-8 large-offset-2">
            {submitError && (
              <div className="alert alert-danger">{submitError}</div>
            )}

            <ApplicationConfigContext.Consumer>
              {() => (
                <ClassificationsContext.Consumer>
                  {classifications => (
                    <AssetFields
                      assetCategories={classifications.assetCategories.filter(
                        cat => cat.name !== ATON_CATEGORY_NAME
                      )}
                      operationalAreas={classifications.operationalAreas}
                      form={form}
                      params={params}
                    />
                  )}
                </ClassificationsContext.Consumer>
              )}
            </ApplicationConfigContext.Consumer>

            <div className="row grid-x">
              <div className="cell auto" />
              <div className="cell padding-left-2 padding-right-2">
                <button
                  className="button primary expanded padded"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
}

Edit.propTypes = {
  history: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired
};

const WrappedEdit = props => {
  const { id } = props.match.params;
  const history = useHistory();
  const { result: asset, isFetching } = useRxDocument('assets', id);

  if (isFetching) {
    return 'Loading...';
  }

  if (!isFetching && !asset) {
    return 'Not found...';
  }

  return <Edit history={history} asset={asset} />;
};

WrappedEdit.propTypes = {
  match: PropTypes.object.isRequired
};

export default WrappedEdit;
