import PropTypes from 'prop-types';
import React from 'react';
import { Field } from '../Html5ValidationField';

const VesselFields = ({ name }) => (
  <>
    <label className="panel-label" htmlFor={`${name}.name`}>
      Vessel name
    </label>
    <Field
      id={`${name}.name`}
      name={`${name}.name`}
      component="input"
      type="text"
    />

    <label className="panel-label" htmlFor={`${name}.description`}>
      Vessel description
    </label>
    <Field
      id={`${name}.description`}
      name={`${name}.description`}
      component="textarea"
    />
  </>
);

VesselFields.propTypes = {
  name: PropTypes.string.isRequired
};

export default VesselFields;
