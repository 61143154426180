// this has been inlined from react-final-form-html5-validation as it does not seem
// to be maintained anymore, and does not list React 17+ as supported peer dependencies
// meaning it makes npm v7+ unhappy without --legacy-peer-deps (which we don't want to do)

import * as React from 'react';
import ReactDOM from 'react-dom';
import { Field } from 'react-final-form';

const warning =
  process.env.NODE_ENV === 'production'
    ? /* istanbul ignore next */
      // eslint-disable-next-line no-unused-vars
      (condition, message) => {}
    : (condition, message) => {
        if (!condition) {
          console.error(`Warning: ${message}`);
        }
      };

const errorKeys = [
  'badInput',
  'patternMismatch',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
  'tooLong',
  'tooShort',
  'typeMismatch',
  'valueMissing'
];

class Html5ValidationField extends React.Component {
  props;
  input;

  static defaultProps = {
    badInput: 'Incorrect input',
    patternMismatch: 'Does not match expected pattern',
    rangeOverflow: 'Value too high',
    rangeUnderflow: 'Value too low',
    stepMismatch: 'Invalid step value',
    tooLong: 'Too long',
    tooShort: 'Too short',
    typeMismatch: 'Invalid value',
    valueMissing: 'Required'
  };

  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    const root = ReactDOM.findDOMNode(this);
    if (root) {
      let input;
      if (/input|textarea|select/.test(root.nodeName.toLowerCase())) {
        input = root;
      } else if (root.querySelector) {
        // eslint-disable-next-line react/prop-types
        const { name } = this.props;
        input = root.querySelector(
          `input[name="${name}"],textarea[name="${name}"],select[name="${name}"]`
        );
      }
      const foundInput = input && typeof input.setCustomValidity === 'function';
      if (foundInput) {
        this.input = input;
      }
      warning(foundInput, 'Could not find DOM input with HTML validity API');
    }
  }

  validate = (value, allValues) => {
    const {
      input,
      // eslint-disable-next-line react/prop-types
      props: { validate }
    } = this;
    if (input) {
      const validity = input && input.validity;
      if (validate) {
        const error = validate(value, allValues);
        if (input.setCustomValidity && typeof error === 'string') {
          input.setCustomValidity(error);
        }
        if (error) {
          return error;
        }
      }
      input.setCustomValidity('');
      if (validity && !validity.valid) {
        if (validity.customError) {
          return validity.customError;
        }
        const errorKey = errorKeys.find(key => validity[key]);
        let error = errorKey && this.props[errorKey];
        if (typeof error === 'function') {
          error = error(value, this.props);
        }
        input.setCustomValidity(error);

        return error;
      }
    } else if (validate) {
      return validate(value, allValues);
    }
  };

  render() {
    /* eslint-disable no-unused-vars, react/prop-types */
    const {
      validate,
      badInput,
      patternMismatch,
      rangeOverflow,
      rangeUnderflow,
      stepMismatch,
      tooLong,
      tooShort,
      typeMismatch,
      valueMissing,
      innerRef,
      ...rest
    } = this.props;
    /* eslint-enable */

    return React.createElement(Field, {
      validate: this.validate,
      ref: innerRef,
      ...rest
    });
  }
}

const field = React.forwardRef((props, ref) =>
  React.createElement(Html5ValidationField, {
    innerRef: ref,
    ...props
  })
);

export { field as Field };
