import jQuery from 'jquery';
import { orderBy } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Field } from '../Html5ValidationField';
import CustomSelect from '../forms/CustomSelect';
import TaxononyChildSelect from '../forms/taxonomy-child-select';

export default function FilterForm({ filters, filterOptions }) {
  const filterForm = useRef(null);
  useEffect(() => {
    jQuery(filterForm.current).foundation();
  }, [filterForm]);

  return (
    <div
      className="dropdown-pane filter-form"
      id="filters-dropdown"
      data-dropdown
      data-auto-focus="true"
      data-alignment="right"
      ref={filterForm}
    >
      {filters.map((filter, domKey) => {
        const {
          label,
          field,
          category,
          id,
          placeholder,
          pattern,
          parentAttribute,
          childAttribute
        } = filter;

        return (
          <div className="cell small-12" key={domKey}>
            {label && field !== 'taxonomy-categories-select' && (
              <label htmlFor={id}>{label}</label>
            )}

            {field === 'pattern' && (
              <Field
                component="input"
                type="text"
                pattern={pattern}
                name={id}
                id={id}
              />
            )}

            {field === 'text' && (
              <Field component="input" type="text" name={id} id={id} />
            )}

            {field === 'date' && (
              <Field component="input" type="date" name={id} id={id} />
            )}

            {field === 'custom-select' && (
              <Field
                name={id}
                id={id}
                component={CustomSelect}
                ariaLabel={label}
                options={orderBy(filterOptions[category], 'name').map(item => {
                  return { value: item.id, label: item.name };
                })}
                isClearable
                placeholder={placeholder}
              />
            )}

            {field === 'taxonomy-categories-select' && (
              <TaxononyChildSelect
                parents={filterOptions[category]}
                required={false}
                parentAttribute={parentAttribute}
                childAttribute={childAttribute}
                label={label}
              />
            )}

            {field === 'select' && (
              <Field component={field} id={id} name={id}>
                <option value="">{'select an option'}</option>
                {filterOptions[category] &&
                  filterOptions[category].map(type => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
              </Field>
            )}
          </div>
        );
      })}
      <button
        data-toggle="filters-dropdown"
        className="button expanded primary"
        type="submit"
      >
        Filter
      </button>
    </div>
  );
}

FilterForm.propTypes = {
  filters: PropTypes.array,
  filterOptions: PropTypes.object
};
