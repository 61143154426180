import { gql, useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import unreadIcon from '../../images/user-tag-unread.svg';
import readIcon from '../../images/user-tag.svg';
import FormattedContent from '../common/content/FormattedContent';
import { localize } from '../common/date-format';
import useNetworkConnectivityStatus from '../common/hooks/useNetworkConnectivityStatus';
import truncate from '../common/truncate';
import GraphqlProvider from '../graphql/Provider';
import GROUPED_NOTIFICATIONS_QUERY from '../graphql/queries/notifications';

const NOTIFICATIONS_STATUS_GQL = gql`
  mutation notificationsStatus(
    $groupIds: [ID!]!
    $newStatus: NotificationGroupStatus!
  ) {
    notificationsStatus(input: { groupIds: $groupIds, newStatus: $newStatus }) {
      groupedNotifications {
        groupId
        notifications {
          id
          readAt
          archivedAt
        }
        logEntry {
          id
          createdBy {
            name
          }
          createdAt
          source {
            __typename
            sequenceNumber
            id
          }
          content
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

function Mention({
  logEntry,
  unread,
  changeNotificationStatus,
  groupId,
  showButtons
}) {
  const {
    content,
    createdAt,
    createdBy,
    id,
    source: {
      id: sourceId,
      sequenceNumber: sourceSequenceNumber,
      __typename: sourceType
    }
  } = logEntry;

  function updateNotificationReadStatus() {
    changeNotificationStatus(groupId, unread ? 'read' : 'unread');
  }

  function archiveNotifications() {
    window.confirm('Are you sure you wish to delete this mention?') &&
      changeNotificationStatus(groupId, 'archived');
  }

  const mentionUrl = unread
    ? `/notifications_group/${groupId}/read`
    : `/${sourceType && sourceType.toLowerCase()}s/${sourceId}`;

  return (
    <div
      id={`mention-${id}`}
      className={`${
        unread && showButtons ? 'unread-mention' : 'mention'
      } grid-x`}
    >
      <a className="grid-x small-12" href={mentionUrl}>
        <div className="cell row small-1 align-self-middle align-center">
          <img src={unread ? unreadIcon : readIcon} className="icon" alt="" />
        </div>
        <div className="cell auto">
          <div className="cell auto grid-x align-justify">
            <h2 className="title">{createdBy.name} mentioned you on</h2>
            <p className="date">
              {localize(createdAt, { format: 'defaultDate' })}
            </p>
          </div>
          <div className="cell auto">
            <h3 className="link">
              {sourceType} #{sourceSequenceNumber}
            </h3>
            <FormattedContent content={truncate(content, 175)} />
          </div>
        </div>
      </a>
      {showButtons && (
        <div className="mention-buttons cell row small-11 small-offset-1 align-justify">
          <button
            type="button"
            className="clickable-target"
            name={`mark-mention-as-${unread ? 'read' : 'unread'}`}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={updateNotificationReadStatus}
          >
            Mark as {`${unread ? 'read' : 'unread'}`}
          </button>
          <button
            type="button"
            name="delete-mention"
            className="clickable-target"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={archiveNotifications}
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
}

function MentionsList({ showButtons }) {
  const [notificationGroups, updateNotificationGroups] = useState([]);
  const [mutationChangeReadState] = useMutation(NOTIFICATIONS_STATUS_GQL);
  const online = useNetworkConnectivityStatus();

  const { error, data, loading } = useQuery(GROUPED_NOTIFICATIONS_QUERY, {
    fetchPolicy: 'cache-and-network'
  });

  if (error) {
    console.error(error);
  }

  async function changeNotificationStatus(groupId, newStatus) {
    let submissionErrors = {};

    try {
      const { data: updatedData } = await mutationChangeReadState({
        variables: { groupIds: [groupId], newStatus }
      });
      const { groupedNotifications, errors } = updatedData.notificationsStatus;
      updateNotificationGroups(groupedNotifications);

      // If we get validation errors, treat this as an error, but present
      // a generic message.
      if (errors.length) {
        console.error(errors);
      }

      return submissionErrors;
    } catch (err) {
      if (err.length) {
        console.error(err);
      }

      return submissionErrors;
    }
  }

  useEffect(() => {
    data && updateNotificationGroups(data.groupedNotifications);
  }, [data]);

  if (!online) {
    return <div className="mention">Mentions are not available offline.</div>;
  }

  return notificationGroups.length > 0 ? (
    notificationGroups.map(group => {
      const { logEntry, groupId, notifications } = group;

      const unread = !notifications[0].readAt;

      return (
        <div key={groupId}>
          <Mention
            logEntry={logEntry}
            // eslint-disable-next-line react/jsx-no-bind
            changeNotificationStatus={changeNotificationStatus}
            unread={unread}
            groupId={groupId}
            showButtons={showButtons}
          />
        </div>
      );
    })
  ) : (
    <div className="mention">
      {loading ? 'Loading mentions...' : 'You have no mentions'}
    </div>
  );
}
// eslint-disable-next-line react/prop-types
function Wrapper({ showButtons = true }) {
  return (
    <GraphqlProvider>
      <MentionsList showButtons={showButtons} />
    </GraphqlProvider>
  );
}

MentionsList.propTypes = {
  showButtons: PropTypes.bool
};
Mention.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  showButtons: PropTypes.bool,
  changeNotificationStatus: PropTypes.func,
  unread: PropTypes.bool,
  groupId: PropTypes.string,
  logEntry: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  )
};

export default Wrapper;
